import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import Button from "../../../components/custom-widgets/button";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import ContactInfo from "../../../components/contact-info/contact-info";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const BusinessCheckingWithInterest = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-checking-account/interest-checking/hero-business-interest-checking-02152023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "interest-checking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Gardeners with tablet in greenhouse surrounded by flowers",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Spend and Earn More for Your Money in One Place"
          }
        },
        {
          id: 2,
          button: {
            id: "interest-checking-hero-cta",
            text: "Open an Account",
            url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyG3",
            class: "btn-white",
            externalLink: true,
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-checking-account",
      title: "Business Checking"
    },
    {
      id: 3,
      active: true,
      title: "Interest Checking"
    }
  ];

  const title = "Business Checking with Interest",
    description =
      "Open a Business Interest Checking account at WaFd bank. Our interest bearing account is the right fit for clients with moderate activity and balances.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-checking-account/interest-checking "
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-interest-checking-02152023.jpg"
      }
    ]
  };

  const cardsData = useCardBlogsData([
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business",
    "/blog/small-business/how-to-grow-small-business"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const articleCardsData = {
    sectionClass: "bg-white",
    hasGrowEffect: true,
    cards: featuredCardsData
  };

  const openAccountBtnData = {
    text: "Open an Account",
    containerClass: "mb-3",
    class: "btn btn-primary w-100 w-sm-auto",
    showIcon: false,
    url: "https://wafd.my.site.com/nPortal__SelfRegistration?core=es5&product=a0uUH000000dyG3",
    externalLink: true,
    target: "blank"
  };

  const iconListData = [
    {
      img: "../../../images/icons/pay-bills-icon.svg",
      text: "Online Banking with Bill Payment"
    },
    {
      img: "../../../images/icons/mobile-deposit-icon.svg",
      text: "Mobile Photo Deposit & Remote Deposit Capture"
    },
    {
      img: "../../../images/icons/sweep-accounts-icon.svg",
      text: "Automatic Sweep Accounts"
    },
    {
      img: "../../../images/icons/eStatements-icon.svg",
      text: "eStatements"
    },
    {
      img: "../../../images/icons/debit-card-icon.svg",
      text: "Visa Debit Card"
    },
    {
      img: "../../../images/icons/eWire-icon.svg",
      text: "eWire Transfer Service"
    },
    {
      img: "../../../images/icons/ach-payments-icon.svg",
      text: "Electronic ACH Payments"
    },
    {
      img: "../../../images/icons/credit-card-processing-icon.svg",
      text: "Credit Card Processing"
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container adjust-padding-bottom">
        <h1>Business Interest Checking</h1>
        <h2 className="text-success">Only $100 initial deposit to open.*</h2>
        <h3>
          WaFd Bank is the{" "}
          <Link
            to="/blog/small-business/what-is-the-best-bank-for-a-small-business"
            id="blog-best-bank-for-small-business-link"
          >
            Best Bank for Small Business
          </Link>
          . This interest-bearing Business Checking Account is the right fit for clients with moderate activity and
          balances. <span className="text-success font-weight-bold">Earn interest with balances above $1,000</span>.
        </h3>
        <Button id="open-account-cta-1" {...openAccountBtnData} />
        <p className="text-sm text-muted mb-0" id="">
          *$15 per month if balance falls below $5,000
        </p>
      </section>
      <section className="container pt-0 adjust-padding-bottom">
        <h2>Everything You Need in a Business Interest Checking Account</h2>
        <div className="row mt-4">
          <div className="col-md-6">
            {iconListData.map((data, index) => (
              <div className="row mb-3" key={index} id={`checking-list-item-${index + 1}`}>
                <div className="col-2 col-sm-1 col-md-2 col-lg-1">
                  <img src={data.img} alt="" />
                </div>
                <div className="col-10 col-lg-11 font-weight-semi-bold">{data.text}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6">
            <StaticImage
              src="../../../images/credit-cards/thumbnail-debit-consumer-horizontal.jpg"
              alt="WaFd Bank contactless debit card"
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
      </section>

      <section className="bg-light adjust-padding-bottom">
        <div className="container">
          <h2>Upgrade your account with these options:</h2>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <span className="font-weight-bold text-success">Remote Deposit Capture:</span> Submit your bank deposit
                using a simple desktop scanning device.
              </h4>
              <ul id="remote-deposit-capture-ul">
                <li id="remote-deposit-capture-li-1">Save time, no need to drive your deposits to the bank</li>
                <li id="remote-deposit-capture-li-2">Receive credit faster</li>
                <li id="remote-deposit-capture-li-3">Access images of deposited items</li>
              </ul>
              <h4>
                <span className="font-weight-bold text-success">eWires:</span> Send Domestic and International wires
                quickly.
              </h4>
            </div>
            <div className="col-md-6">
              <h4>
                <span className="font-weight-bold text-success">eACH:</span> A secure and fast way to send and receive
                payments.
              </h4>
              <ul id="e-ach-ul">
                <li id="e-ach-li-1">Vendor Payments</li>
                <li id="e-ach-li-2">Payroll</li>
                <li id="e-ach-li-3">Payment Collection</li>
              </ul>
              <h4>
                <span className="font-weight-bold text-success">
                  CollectEarly<sup>&trade;</sup>:
                </span>{" "}
                Receive invoice payments in four HOURS instead of four weeks.
              </h4>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...articleCardsData} />
      <ContactInfo type="business" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default BusinessCheckingWithInterest;
